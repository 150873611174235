import React from "react";
import Card from "@material-ui/core/Card";
import { Link } from "gatsby";
import { Grid } from "@material-ui/core";
import RightArrow from "../../assets/Icons/go.svg";
import whiteAccordian from "../../assets/Images/Homepage/Learn_More_white.svg";
import Button from "../../components/Button";
import "./OurLocation.scss";
import RevealAnimation from "../../components/RevealAnimation";

const LocationCards = (props) => {
  return (
    <div className="mt-30 ">
      <RevealAnimation animation="zoom-in" className="body-container">
        {" "}
        <img className="map-image" src={props.mapImage} alt={props.imgAlt} />
      </RevealAnimation>

      <div className="" style={{ marginBottom: -50 }}>
        <Grid
          container
          // style={{background:'#F5E7E2'}}
          direction="row"
          className="body-container-location"
          spacing={0.5}
        >
          {props.LocationCardData &&
            props.LocationCardData.locationImages.map((item, index) => (
              //   <Link
              //   style={{ textDecoration: "none", color: "black" }}
              //   to={`/${item.route}`}
              // >
              <Grid
                key={index}
                item
                xs={6}
                sm={6}
                md={4}
                style={{ marginTop: "-66px", marginBottom: "88px" }}
                className="card-width"
              >
                <Card
                  style={{}}
                  className="mt-30 body-container location-card-style appsgridshadow"
                >
                  <img
                    className="location-image"
                    src={item.image}
                    alt={item.image}
                  />
                  <div className="img-title ">{item.imgTitle}</div>
                  {/* <div className="accordian-condition-location">
              {" "}
              {props.button !== "no" && (
                <Button
                  icon={
                    !props.variant && (
                      <img
                        className="effect-btn-svgicon"
                        src={RightArrow}
                        alt="click"
                      />
                    )
                  }
                  customClassName="effect-btn"
                  tip={props.label}
                  color="primary"
                  label={props.label}
                  variant={props.variant}
                  routepath={item.route}
                  tooltip={props.tooltip}
                />
              )}{" "}
            </div> */}
                </Card>
              </Grid>
              // </Link>
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default LocationCards;
